import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CSpinner } from "@coreui/react-pro";
import "./scss/style.scss";
import "react-toggle/style.css";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/login/Login"));
const PdfPrint = React.lazy(() => import("./views/pdf-print/PdfPrint"));

const QR = React.lazy(() => import("./views/qr/Qr"));
const QrResult = React.lazy(() => import("./views/qr/QrResult"));
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Toaster />
        <Suspense fallback={<CSpinner color="primary" />}>
          <Switch>
            {/* WITH AUTH */}
            <Route exact path="/pdf-print/:id" component={PdfPrint} />

            <Route exact path="/qr" component={QR} />
            <Route exact path="/data/product/:id/:type" component={QrResult} />

            <PublicRoute exact path="/login" component={Login} />
            <PrivateRoute path="/" component={DefaultLayout} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
